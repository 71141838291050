export const menuItems = [
    /*{
        id: 1,
        //label: "menuitems.menu.text",
        //isTitle: true
    },*/
    //PROCHAIN ID EST 106
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
       /*  badge: {
            variant: "success",
            text: "1"
        }, */
        link: '/',
    },



    //RMOBILITY TAXI

{
    id: 88,
    label: 'RMOBILITY TAXI',
    isTitle: true,
    groupe: 18, 
},

{
    id: 89,
    label: 'Users VTC',
    icon: 'fa fa-taxi',
    link: '/taxi/user-list',
    groupe: 18, 
},
{
    id: 99,
    label: 'Recherche',
    icon: 'fa fa-taxi',
    link: '/recherche/taxi',
    groupe: 18, 
},

// {
//     id: 105,
//     label: 'Planification',
//     icon: 'fa fa-taxi',
//     link: '/taxi/planification',
//     groupe: 18, 
// },

{
    id: 90,
    label: 'Zone d\'activité',
    icon: 'fa fa-map',
    link: '/taxi/activity-area',
    groupe: 18, 
},

{
    id: 104,
    label: 'Vue globale',
    icon: 'fa fa-map',
    link: '/taxi/globale-vue',
    groupe: 18, 
},


{
    id: 91,
    label: 'Toutes courses',
    icon: 'fa fa-road',
    link: '/taxi/course',
    groupe: 18, 
},


{
    id: 96,
    label: 'Zone de couverture',
    icon: 'fas fa-map ',
    link: '/taxi/area',
    groupe: 18,  
},
// {
//     id: 106,
//     label: 'Lieu de référence',
//     icon: 'fas fa-map ',
//     link: '/taxi/references',
//     groupe: 18,  
// },


   
    //Trajets
    {
        id: 2,
        label: 'menuitems.menu_trajets.text',
        isTitle: true,
        groupe: 1,
    },
    
    /*{
        id: 3,
        label: 'Ajouter',
        icon: 'fa fa-plus',
        link: '/newtrajet',
        groupe: 1,
    },*/


    {
        id: 3,
        label: 'menuitems.encours.text',
        icon: 'fas fa-spinner',
        link: '/trajets/encours',
        groupe: 1,
    },
    {
        id: 4,
        label: 'menuitems.urgences.text',
        icon: 'fas fa-exclamation-triangle',
        link: '/trajets/urgences',
        groupe: 1,
    },

    {
        id: 5,
        label: 'Publiés',
        icon: 'fas fa-map-marked-alt',
        link: '/trajets/publies',
        groupe: 1,
    },

   
    {
        id: 6,
        label: 'Attribués',
        icon: 'fa fa-plus',
        link: '/attribuertrajet',
        groupe: 1,
    },

    {
        id: 7,
        label: 'menuitems.avenirs.text',
        icon: 'fas fa-map-marker-alt',
        link: '/trajets/avenir',
        groupe: 1,
    },

    {
        id: 8,
        label: 'Passés',
        icon: 'fa fa-road',
        link: '/trajets/passe',
        groupe: 1,
    },





    
    {
        id: 9,
        label: 'Recherches',
        icon: 'fas fa-search',
        link: '/trajets/recherche',
        groupe: 1,
    },
   
    {
        id: 10,
        label: 'menuitems.alerte_trajet.text',
        icon: 'fas fa-exclamation-triangle',
        link: '/trajets/alertes',
        groupe: 1,
    },
   

   

    {
        id: 86,
        label: 'Demande de réservation',
        icon: 'fas fa-list',
        link: '/trajets/reservation',
        groupe: 1,
    },

    {
        id: 25,
        label: 'menuitems.historique.text',
        icon: 'fas fa-cash-register',
        link: '/transactions/historique-transactions',
        groupe: 1, 
    },

    {
        id: 85,
        label: 'menuitems.annules.text',
        icon: 'fas fa-ban',
        link: '/trajets/annules',
        groupe: 1,
    },


    {
        id: 11,
        label: 'menuitems.effectues.text',
        icon: 'fas fa-map-marked-alt',
        link: '/trajets/effectues',
        groupe: 1,
    },
    {
        id: 81,
        label: 'Fin Trajets',
        icon: 'fas fa-check',
        link: '/trajets/status-trajets',
        groupe: 1,
    },





  
    
   
   
    //Réservation
    /*{
        id: 11,
        label: 'menuitems.menu_reservation.text',
        isTitle: true,
        groupe: 2,
    },
    {
        id: 12,
        label: 'menuitems.add_reservation.text',
        icon: 'fas fa-calendar-day',
        link: '/reservation/add_reservation',
        groupe: 2,
    },
    {
        id: 13,
        label: 'menuitems.list_reservation.text',
        icon: 'fas fa-list',
        link: '/reservation/reservation_list',
        groupe: 2,
    },*/

    //Utilisateur
    {
        id: 13,
        label: 'menuitems.menu_utilisateurs.text',
        isTitle: true,
        groupe: 3,
    },
    {
        id: 14,
        label: 'menuitems.ajouter.text',
        icon: 'fas fa-user-plus',
        link: '/user/add_users',
        groupe: 3,
    },
    {
        id: 15,
        label: 'menuitems.liste.text',
        icon: 'fas fa-users',
        link: '/user/users_list',
        groupe: 3,
    },
    {
        id: 87,
        label: 'Compagnies',
        icon: 'fas fa-bus',
        link: '/agence/compagnie',
        groupe: 3, 
    },
    {
        id: 101,
        label: 'Recherche Ticket de Bus',
        icon: 'fa fa-bus',
        link: '/recherche/bus',
        groupe: 3, 
    },
   

    {
        id: 16,
        label: 'menuitems.domicile.text',
        icon: 'fas fa-home',
        link: '/user/domicile',
        groupe: 3,
    },
    {
        id: 17,
        label: 'menuitems.rmobility_avis_utilisateur.text',
        icon: 'fas fa-star',
        link: '/user/avis_user',
        groupe: 3, 
    },

    {
        id: 18,
        label: ' Signalés',
        icon: 'fas fa-info-circle ',
        link: '/alertes/details_alertes',
        groupe: 3, 
    },
    {
        id: 22,
        label: ' Supprimés',
        icon: 'fas fa-user-minus',
        link: '/user/supp_users',
        groupe: 3,
    },
    {
        id: 23,
        label: 'Rappels pièces',
        icon: 'fas fa-chart-bar',
        link: '/user/statistiques',
        groupe: 3,
    },
   
    {
        id: 76,
        label: ' Gains parrains',
        icon: 'fa fa-money',
        link: '/users/gains_parrains',
        groupe: 3,
    },


//Recherche
// {
//     id: 93,
//     label: 'Recherche',
//     isTitle: true,
//     groupe: 19, 
// },


// {
//     id: 98,
//     label: 'Covoiturage',
//     icon: 'fa fa-car',
//     link: '/trajets/recherche',
//     groupe: 19, 
// },



    
    //Wallet
    {
        id: 37,
        label: 'menuitems.menu_wallet.text',
        isTitle: true,
        groupe: 7, 
    },
    {
        id: 38,
        label: 'menuitems.wallet.text',
        icon: 'fas fa-list',
        link: '/wallet/solde',
        groupe: 7,
    },
    {
        id: 39,
        label: 'menuitems.wallet_historique.text',
        icon: 'fas fa-history',
        link: '/wallet/transactions',
        groupe: 7, 
    },
    //transactions
    {
        id: 24,
        label: 'menuitems.menu_transactions.text',
        isTitle: true,
        groupe: 7, 
    },
    {
        id: 29,
        label: 'menuitems.historique_virement.text',
        icon: 'fas fa-money-check ',
        link: '/virements/historique-virements',
        groupe: 9,  
    },
    {
        id: 103,
        label: 'Retrait',
        icon: 'fas fa-money-check ',
        link: '/virements/retrait',
        groupe: 9,  
    },
    {
        id: 57,
        label: 'menuitems.historique_remboursement.text',
        icon: 'fas fa-mail-bulk',
        link: '/virements/remboursement',
        groupe: 9,  
    },

    {
        id: 27,
        label: 'menuitems.historique_gain.text',
        icon: 'fas fa-coins ',
        link: '/suivis/historique',
        groupe: 8, 
    },
    //General
   
    {
        id: 35,
        label: 'Général',
        isTitle: true,
        groupe: 12, 
    },

    {
        id: 100,
        label: 'Publicite',
        icon: 'far fa-bullhorn',
        link: '/general/publicite',
        groupe: 12,
    },

    {
        id: 72,
        label: 'menuitems.bon-plan.text',
        icon: 'far fa-calendar-alt',
        link: '/bon_plans/bons_plans',
        groupe: 12,
    },


     
    {
        id: 73,
        label: 'Évènements',
        icon: 'far fa-calendar-check',
        link: '/event/evenement',
        groupe: 12,
    }, 
    {
        id: 84,
        label: 'Achats Tickets',
        icon: 'fas fa-ticket-alt',
        link: '/event/pass/buy',
        groupe: 12,
    }, 
    {
        id: 83,
        label: 'Gestion Lots',
        icon: 'fas fa-gift',
        link: '/gifts',
        groupe: 12, 
    },
    {
        id: 36,
        label: 'Réductions',
        icon: 'fas fa-qrcode',
        link: '/codepromo',
        groupe: 12, 
    },
    {
        id: 56,
        label: 'Anniversaires',
        icon: 'far fa-calendar-alt',
        link: '/promoannif',
        groupe: 12, 
    },
    {
        id: 40,
        label: 'Parrainages',
        icon: 'fas fa-list',
        link: '/parrainage',
        groupe: 12, 
    },

     {
        id: 68,
        label: 'Actualités',
        icon: 'fas fa-newspaper',
        link: '/actualite',
        groupe: 12, 
    },

     {
        id: 69,
        label: 'Informations',
        icon: 'far fa-clipboard',
        link: '/informations',
        groupe: 12, 
    },


    {
        id: 79,
        label: 'Blogs',
        icon: 'far fa-clipboard',
        link: '/blog',
        groupe: 12, 
    },

    {
        id: 80,
        label: 'Apk Upload',
        icon: 'fas fa-upload',
        link: '/appupload',
        groupe: 12, 
    },

     {
        id: 70,
        label: 'Conseils passagers',
        icon: 'fas fa-users',
        link: '/conseil',
        groupe: 12, 
    },

    {
        id: 71,
        label: 'Conseils conducteurs',
        icon: 'fas fa-chalkboard-teacher',
        link: '/conseil_conducteur',
        groupe: 12, 
    },
    {
        id: 41,
        label: 'Trafics',
        icon: 'fas fa-traffic-light',
        link: '/infostrafic',
        groupe: 12, 
    },
   

   

   


    

   
   

    
    //bons plan
/*     {
        id: 21,
        label: 'menuitems.menu_bons_plans.text',
        isTitle: true,
        groupe: 5,
    },
    {
        id: 22,
        label: 'menuitems.bon-plan.text',
        icon: 'fas fa-plus ',
        link: '/bon_plans/bons_plans',
        groupe: 5,
    }, */
    //don
    {
        id: 21,
        label: 'menuitems.menu_dons.text',
        isTitle: true,
        groupe: 4,
    },
    {
        id: 19,
        label: 'menuitems.ajouter_dons.text',
        icon: 'fas fa-plus',
        link: '/dons/add_dons',
        groupe: 4,
    },
    {
        id: 20,
        label: 'menuitems.liste_dons.text',
        icon: 'fas fa-list',
        link: '/dons/list_dons',
        groupe: 4,
    },
    //alertes
    /*{
        id: 22,
        label: 'Signalé',
        isTitle: true,
        groupe: 6,
    },
    {
        id: 23,
        label: 'Liste',
        icon: 'fas fa-info-circle ',
        link: '/alertes/details_alertes',
        groupe: 6, 
    },*/
    //Message
    {
        id: 32,
        label: 'Messages',
        isTitle: true,
        groupe: 11, 
    },
    {
        id: 33,
        label: 'Nouveau',
        icon: 'ri-mail-add-fill',
        link: '/message/nouveau',
        groupe: 11, 
    },
    {
        id: 75,
        label: ' Conversations',
        icon: 'fas fa-comment',
        link: '/agence/discussions',
        groupe: 3,  
    },
    
    {
        id: 34,
        label: 'Historique',
        icon: 'ri-mail-check-line',
        link: '/message/historique',
        groupe: 11,  
    },

 //discussion
 {
    id: 59,
    label: 'menuitems.menu_discussion.text',
    isTitle: true,
    groupe: 16, 
},
/* {
    id: 60,
    label: 'Actifs',
    icon: 'fas fa-comment-alt',
    link: '/discussions/chatactif',
    groupe: 11,  
}, */
{
    id: 61,
    label: 'Archivées',
    icon: 'fa fa-archive',
    link: '/discussions/chatarchive',
    groupe: 16,  
},





//Formules
{
    id: 64,
    label: 'menuitems.menu_souscription.text',
    isTitle: true,
    groupe: 14, 
},

{
    id: 63,
    label: 'Souscription',
    icon: 'fa fa-id-card',
    link: '/souscription/list-souscription',

    groupe: 14, 
},

// {
//     id: 65,
//     label: 'Formules',
//     icon: 'fa fa-handshake-o',
//     link: '/assurance/option-assurance',
//     groupe: 14, 
// },



//NSIA

{
    id: 66,
    label: 'menuitems.menu_nsia.text',
    isTitle: true,
    groupe: 15, 
},

{
    id: 67,
    label: 'Vue NSIA',
    icon: 'fa fa-id-card',
    link: '/nsia/nsia_souscription',
    groupe: 15, 
},

//VIP

{
    id: 77,
    label: 'menuitems.menu_vpi.text',
    isTitle: true,
    groupe: 17, 
},

{
    id: 78,
    label: ' VIP',
    icon: 'fa fa-fax',
    link: '/vpi/list_vpi',
    groupe: 17, 
},









//RMobility location
//agence/compagnie

    {
        id: 46,
        label: 'menuitems.menu_rmobility_location.text',
        isTitle: true,
        groupe: 13, 
    },
    // {
    //     id: 47,
    //     label: 'menuitems.rmobility_location_agence.text',
    //     icon: 'far fa-building',
    //     link: '/agence/demandes',
    //     groupe: 13,  
    // },
    {
        id: 94,
        label: 'Historique Location',
        icon: 'far fa-clock',
        link: '/location/historique',
        groupe: 13,  
    },
    {
        id: 48,
        label: 'Annonces en attente',
        icon: 'far fa-address-book',
        link: '/agence/demandes_user',
        groupe: 13,  
    },
    {
        id: 49,
        label: 'menuitems.rmobility_location_user_liste.text',
        icon: 'fas fa-user-friends',
        link: '/agence/user_list',
        groupe: 13,  
    },
    // {
    //     id: 50,
    //     label: 'menuitems.rmobility_location_agence_liste.text',
    //     icon: 'fas fa-city',
    //     link: '/agence/agencelist',
    //     groupe: 13,  
    // },
    // {
    //     id: 51,
    //     label: 'menuitems.rmobility_location_abonne_liste.text',
    //     icon: 'far fa-id-card',
    //     link: '/agence/list_abonne',
    //     groupe: 13,  
    // },
    {
        id: 74,
        label: 'menuitems.rmobility_location_reservation.text',
        icon: 'far fa-id-card',
        link: '/agence/demande_reservation',
        groupe: 13,  
    },
    



    {
        id: 52,
        label: 'menuitems.rmobility_location_paiement_historique.text',
        icon: 'fas fa-mail-bulk',
        link: '/agence/historique_paiement',
        groupe: 13,  
    },
    {
        id: 53,
        label: 'menuitems.rmobility_location_virement_historique.text',
        icon: 'far fa-share-square',
        link: '/agence/virements',
        groupe: 13,  
    },
    {
        id: 54,
        label: 'Remboursements',
        icon: 'far fa-share-square',
        link: '/agence/remboursements',
        groupe: 13,  
    },

   
   

   


    //administrateur
    {
        id: 30,
        label: 'menuitems.menu_administrateur.text',
        isTitle: true,
        groupe: 10, 
    },
    {
        id: 31,
        label: 'Gestion des accès',
        icon: 'fas fa-lock ',
        link: '/admin/add-admin',
        groupe: 10,  
    },
    {
        id: 92,
        label: 'Gestion commisions',
        icon: 'fa fa-dollar-sign',
        link: '/taxi/commision',
        groupe: 18,
    },
   
    {
        id: 95,
        label: 'Gestion des Services',
        icon: 'fas fa-lock ',
        link: '/admin/services',
        groupe: 10,  
    },

    {
        id: 97,
        label: 'Web Annexes',
        icon: 'fas fa-book',
        link: '/admin/confidentialite',
        groupe: 10,  
    },
    {
        id: 102,
        label: 'Taxes et Impots',
        icon: 'fas fa-coins',
        link: '/admin/taxes',
        groupe: 10,  
    },
   
    /* {
        id: 42,
        label: 'menuitems.admin.anothertext',
        icon: 'fas fa-info-circle ',
        link: '/admin/notif',
        groupe: 10,  
    },

 */
    




]


