import axios from "axios";

//const apiUrl = "http://127.0.0.1:1337";
//const apiUrl = "https://rmobility.ovh";
//const apiUrl = "http://192.168.1.105:1337";
const apiUrl = "https://rmobility.ovh";
//const apiUrl = "https://rmobilitytest.raynis.co"


export async function apiRequest(
  method, // "GET" | "DELETE" | "HEAD" | "OPTIONS" | "POST" | "PUT" | "PATCH" | "LINK" | "UNLINK" | undefined,
  endPoint, // string,
  variables, // any,
  formData, //boolean
) {

  let authToken; //: string | undefined | null = undefined;
  let user = JSON.parse(localStorage.getItem('user'));
  //authToken = localStorage.getItem("token");
  authToken = user.tokenSalt;

  try {
    const options = {
      method,
      url: `${apiUrl}/${endPoint}`,
      data: variables,
      headers: {
        }
    };

    if (authToken) options.headers["authorization"] = `Bearer ${authToken}`;

    if (formData)
      options.headers["Content-Type"] = "application/x-www-form-urlencoded";

    const resp = await axios(options);
    if (!resp) throw new Error("Error from API: " + resp);
    return resp;
  } catch (err) {
    console.error("[ERROR]:", err);
    const resp = err.response;
    if (resp && resp.status === 401) {
      localStorage.removeItem("accessToken");
      return resp.status
    } else {
      //throw 
      return new Error(err);
    }
  }
}
